// exGeneralRemarksReducer.js

const exSchoolsGeneralRemarks = [
  {
    std_id: "111",
    remark:
      "Agather is overally a great student, she is excellent in mathematics and maths related fields. There are no challenges that she cannot tackle.",
  },
  {
    std_id: "222",
    remark:
      "Mamion is overally a great student, she is excellent in mathematics and maths related fields. There are no challenges that she cannot tackle.",
  },
  {
    std_id: "333",
    remark:
      "Ashley is overally a great student, she is excellent in mathematics and maths related fields. There are no challenges that she cannot tackle.",
  },
];

const exGeneralRemarksReducer = (state = exSchoolsGeneralRemarks, action) => {
  return state;
};

export default exGeneralRemarksReducer;
