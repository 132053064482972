const initState = {
  allStudents: [
    /////////STUDENT DATA CHUNK START//////////
    {
      id: "1",
      student_current_grade: "10",
      student_current_class: "10b",
      identification: {
        name: "Agatha",
        other: null,
        surname: "Matthews",
        identity_number: "qaeworflsel",
        residential_address: "North Industrial area, Windhoek, Namibia",
        postal_address: "Northen Industrial zone post office",
        gender: "female",
        name_of_guardian: "George Matthews",
        name_of_mother: "Martha Thompson",
        occupation_of_guardian: "Plumber",
        ocuupation_of_mother: "Doctor",
        home_phone: "061344893",
        business_phone: "0813685221",
        church: "Angican",
        home_language: "Afrikaans",
      },
      schools_attended: {
        exemption_from_compulsory_education: false,
        date: null,
        age_on_initial_entry_to_school: "9",
        schools_details: [
          {
            id: "a1",
            admission_no: "11224",
            name_of_school: "Amazing kids",
            medium: null,
            date_of_admission: "1/1/2002",
            grade_of_admission: "8",
            date_of_departure: "11/12/2009",
            grade_of_departure: "7",
          },
          {
            id: "a2",
            admission_no: "11223",
            name_of_school: "Windhoek High",
            medium: null,
            date_of_admission: "12/06/2009",
            grade_of_admission: "8",
            date_of_departure: "28/08/2010",
            grade_of_departure: "9",
          },
          {
            id: "a3",
            admission_no: "11224",
            name_of_school: "DHPS",
            medium: null,
            date_of_admission: "12/06/2010",
            grade_of_admission: "9",
            date_of_departure: "12/11/2014",
            grade_of_departure: "12",
          },
        ],
      },
      physical_condition: [
        {
          id: "a1",
          date: "june 28",
          general_health: "normal",
          problem: "asthmatic",
          current_solution: "Inhaler",
          previous_illness: "chest pains",
        },
      ],
      psychometric_data: [
        {
          id: null,
          date: null,
          name_of_test: null,
          grade: null,
          tester: null,
          remarks: null,
        },
      ],
      learning_disabilities: {
        nature: null,
        action_taken: null,
        results: null,
      },
      problematic_behaviour: {
        nature_of_offence: "Too talkative",
        action_action_taken: "Daily detention",
        results: "Talks less while in class",
      },
      observation_report: {
        psychological: [
          {
            id: "bc",
            grade: "9",
            year: "2001",
            report:
              "Agatha has a strong mental character and she is less emotional in dire situations",
          },
        ],
        social: [
          {
            id: "bb",
            grade: "10",
            year: "2002",
            report: "Agatha has serious challenges with making new friends",
          },
        ],
        overall_impression: [
          {
            id: "b9",
            grade: "12",
            year: "2004",
            report:
              "The girl has improved socially, she made 3 friends in her chemistry class",
          },
        ],
      },
      scholastic_achievements: {
        primary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: "1995",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: "2",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          level_of_grade: {
            col1: "b",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          english_home: {
            col1: "Y",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: "mn",
              name: "Mathematics",
              cols: {
                col1: "90",
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: "    90",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: "A",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: "P",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: "G",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },

        secondary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: "11",
            col9: "11",
            col10: "12",
            col11: "12",
          },
          level_of_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            H: "I",
            col10: "H",
            col11: "I",
          },
          english_home: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: null,
              name: null,
              cols: {
                col1: null,
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },
      },
      general_information: {
        outstanding_aptitudes_interests: {
          aptitudes: "Very good in mathematics",
          interests: "Loves football and hockey",
        },
        outstanding_achievements_attained: {
          academic: "Won the spelling bee three times in a role",
          extra_curricukar:
            "Still holds the trophy for best soccer player in the district",
        },
        vocational_choice: {
          careers_chosen_by_learner: "Doctor",
          careers_chosen_by_parents: "Nurse",
          counsellors_recommendations: "Pilot",
          broad_vocational_field: "Business science",
          specific_careers: "Accountant, life coach",
        },
      },
      general_remarks:
        "Agather is overally a great student, she is excellent in mathematics and maths related fields. There are no challenges that she cannot tackle.",
    },
    /////////STUDENT DATA CHUNK END//////////

    {
      id: "1",
      student_current_grade: "10",
      student_current_class: "10b",
      identification: {
        name: "Agatha",
        other: null,
        surname: "Matthews",
        identity_number: "qaeworflsel",
        residential_address: "North Industrial area, Windhoek, Namibia",
        postal_address: "Northen Industrial zone post office",
        gender: "female",
        name_of_guardian: "George Matthews",
        name_of_mother: "Martha Thompson",
        occupation_of_guardian: "Plumber",
        ocuupation_of_mother: "Doctor",
        home_phone: "061344893",
        business_phone: "0813685221",
        church: "Angican",
        home_language: "Afrikaans",
      },
      schools_attended: {
        exemption_from_compulsory_education: false,
        date: null,
        age_on_initial_entry_to_school: "9",
        schools_details: [
          {
            id: "a1",
            admission_no: "11224",
            name_of_school: "Amazing kids",
            medium: null,
            date_of_admission: "1/1/2002",
            grade_of_admission: "8",
            date_of_departure: "11/12/2009",
            grade_of_departure: "7",
          },
          {
            id: "a2",
            admission_no: "11223",
            name_of_school: "Windhoek High",
            medium: null,
            date_of_admission: "12/06/2009",
            grade_of_admission: "8",
            date_of_departure: "28/08/2010",
            grade_of_departure: "9",
          },
          {
            id: "a3",
            admission_no: "11224",
            name_of_school: "DHPS",
            medium: null,
            date_of_admission: "12/06/2010",
            grade_of_admission: "9",
            date_of_departure: "12/11/2014",
            grade_of_departure: "12",
          },
        ],
      },
      physical_condition: [
        {
          id: "a1",
          date: "june 28",
          general_health: "normal",
          problem: "asthmatic",
          current_solution: "Inhaler",
          previous_illness: "chest pains",
        },
      ],
      psychometric_data: [
        {
          id: null,
          date: null,
          name_of_test: null,
          grade: null,
          tester: null,
          remarks: null,
        },
      ],
      learning_disabilities: {
        nature: null,
        action_taken: null,
        results: null,
      },
      problematic_behaviour: {
        nature_of_offence: "Too talkative",
        action_action_taken: "Daily detention",
        results: "Talks less while in class",
      },
      observation_report: {
        psychological: [
          {
            id: "bc",
            grade: "9",
            year: "2001",
            report:
              "Agatha has a strong mental character and she is less emotional in dire situations",
          },
        ],
        social: [
          {
            id: "bb",
            grade: "10",
            year: "2002",
            report: "Agatha has serious challenges with making new friends",
          },
        ],
        overall_impression: [
          {
            id: "b9",
            grade: "12",
            year: "2004",
            report:
              "The girl has improved socially, she made 3 friends in her chemistry class",
          },
        ],
      },
      scholastic_achievements: {
        primary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: "1995",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: "2",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          level_of_grade: {
            col1: "b",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          english_home: {
            col1: "Y",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: "mn",
              name: "Mathematics",
              cols: {
                col1: "90",
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: "    90",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: "A",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: "P",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: "G",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },

        secondary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: "11",
            col9: "11",
            col10: "12",
            col11: "12",
          },
          level_of_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            H: "I",
            col10: "H",
            col11: "I",
          },
          english_home: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: null,
              name: null,
              cols: {
                col1: null,
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },
      },
      general_information: {
        outstanding_aptitudes_interests: {
          aptitudes: "Very good in mathematics",
          interests: "Loves football and hockey",
        },
        outstanding_achievements_attained: {
          academic: "Won the spelling bee three times in a role",
          extra_curricukar:
            "Still holds the trophy for best soccer player in the district",
        },
        vocational_choice: {
          careers_chosen_by_learner: "Doctor",
          careers_chosen_by_parents: "Nurse",
          counsellors_recommendations: "Pilot",
          broad_vocational_field: "Business science",
          specific_careers: "Accountant, life coach",
        },
      },
      general_remarks:
        "Agather is overally a great student, she is excellent in mathematics and maths related fields. There are no challenges that she cannot tackle.",
    },
    /////////STUDENT DATA CHUNK END//////////

    {
      id: "1",
      student_current_grade: "10",
      student_current_class: "10b",
      identification: {
        name: "Agatha",
        other: null,
        surname: "Matthews",
        identity_number: "qaeworflsel",
        residential_address: "North Industrial area, Windhoek, Namibia",
        postal_address: "Northen Industrial zone post office",
        gender: "female",
        name_of_guardian: "George Matthews",
        name_of_mother: "Martha Thompson",
        occupation_of_guardian: "Plumber",
        ocuupation_of_mother: "Doctor",
        home_phone: "061344893",
        business_phone: "0813685221",
        church: "Angican",
        home_language: "Afrikaans",
      },
      schools_attended: {
        exemption_from_compulsory_education: false,
        date: null,
        age_on_initial_entry_to_school: "9",
        schools_details: [
          {
            id: "a1",
            admission_no: "11224",
            name_of_school: "Amazing kids",
            medium: null,
            date_of_admission: "1/1/2002",
            grade_of_admission: "8",
            date_of_departure: "11/12/2009",
            grade_of_departure: "7",
          },
          {
            id: "a2",
            admission_no: "11223",
            name_of_school: "Windhoek High",
            medium: null,
            date_of_admission: "12/06/2009",
            grade_of_admission: "8",
            date_of_departure: "28/08/2010",
            grade_of_departure: "9",
          },
          {
            id: "a3",
            admission_no: "11224",
            name_of_school: "DHPS",
            medium: null,
            date_of_admission: "12/06/2010",
            grade_of_admission: "9",
            date_of_departure: "12/11/2014",
            grade_of_departure: "12",
          },
        ],
      },
      physical_condition: [
        {
          id: "a1",
          date: "june 28",
          general_health: "normal",
          problem: "asthmatic",
          current_solution: "Inhaler",
          previous_illness: "chest pains",
        },
      ],
      psychometric_data: [
        {
          id: null,
          date: null,
          name_of_test: null,
          grade: null,
          tester: null,
          remarks: null,
        },
      ],
      learning_disabilities: {
        nature: null,
        action_taken: null,
        results: null,
      },
      problematic_behaviour: {
        nature_of_offence: "Too talkative",
        action_action_taken: "Daily detention",
        results: "Talks less while in class",
      },
      observation_report: {
        psychological: [
          {
            id: "bc",
            grade: "9",
            year: "2001",
            report:
              "Agatha has a strong mental character and she is less emotional in dire situations",
          },
        ],
        social: [
          {
            id: "bb",
            grade: "10",
            year: "2002",
            report: "Agatha has serious challenges with making new friends",
          },
        ],
        overall_impression: [
          {
            id: "b9",
            grade: "12",
            year: "2004",
            report:
              "The girl has improved socially, she made 3 friends in her chemistry class",
          },
        ],
      },
      scholastic_achievements: {
        primary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: "1995",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: "2",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          level_of_grade: {
            col1: "b",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          english_home: {
            col1: "Y",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: "mn",
              name: "Mathematics",
              cols: {
                col1: "90",
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: "    90",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: "A",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: "P",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: "G",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },

        secondary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: "11",
            col9: "11",
            col10: "12",
            col11: "12",
          },
          level_of_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            H: "I",
            col10: "H",
            col11: "I",
          },
          english_home: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: null,
              name: null,
              cols: {
                col1: null,
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },
      },
      general_information: {
        outstanding_aptitudes_interests: {
          aptitudes: "Very good in mathematics",
          interests: "Loves football and hockey",
        },
        outstanding_achievements_attained: {
          academic: "Won the spelling bee three times in a role",
          extra_curricukar:
            "Still holds the trophy for best soccer player in the district",
        },
        vocational_choice: {
          careers_chosen_by_learner: "Doctor",
          careers_chosen_by_parents: "Nurse",
          counsellors_recommendations: "Pilot",
          broad_vocational_field: "Business science",
          specific_careers: "Accountant, life coach",
        },
      },
      general_remarks:
        "Agather is overally a great student, she is excellent in mathematics and maths related fields. There are no challenges that she cannot tackle.",
    },
    /////////STUDENT DATA CHUNK END//////////

    {
      id: "1",
      student_current_grade: "10",
      student_current_class: "10b",
      identification: {
        name: "Agatha",
        other: null,
        surname: "Matthews",
        identity_number: "qaeworflsel",
        residential_address: "North Industrial area, Windhoek, Namibia",
        postal_address: "Northen Industrial zone post office",
        gender: "female",
        name_of_guardian: "George Matthews",
        name_of_mother: "Martha Thompson",
        occupation_of_guardian: "Plumber",
        ocuupation_of_mother: "Doctor",
        home_phone: "061344893",
        business_phone: "0813685221",
        church: "Angican",
        home_language: "Afrikaans",
      },
      schools_attended: {
        exemption_from_compulsory_education: false,
        date: null,
        age_on_initial_entry_to_school: "9",
        schools_details: [
          {
            id: "a1",
            admission_no: "11224",
            name_of_school: "Amazing kids",
            medium: null,
            date_of_admission: "1/1/2002",
            grade_of_admission: "8",
            date_of_departure: "11/12/2009",
            grade_of_departure: "7",
          },
          {
            id: "a2",
            admission_no: "11223",
            name_of_school: "Windhoek High",
            medium: null,
            date_of_admission: "12/06/2009",
            grade_of_admission: "8",
            date_of_departure: "28/08/2010",
            grade_of_departure: "9",
          },
          {
            id: "a3",
            admission_no: "11224",
            name_of_school: "DHPS",
            medium: null,
            date_of_admission: "12/06/2010",
            grade_of_admission: "9",
            date_of_departure: "12/11/2014",
            grade_of_departure: "12",
          },
        ],
      },
      physical_condition: [
        {
          id: "a1",
          date: "june 28",
          general_health: "normal",
          problem: "asthmatic",
          current_solution: "Inhaler",
          previous_illness: "chest pains",
        },
      ],
      psychometric_data: [
        {
          id: null,
          date: null,
          name_of_test: null,
          grade: null,
          tester: null,
          remarks: null,
        },
      ],
      learning_disabilities: {
        nature: null,
        action_taken: null,
        results: null,
      },
      problematic_behaviour: {
        nature_of_offence: "Too talkative",
        action_action_taken: "Daily detention",
        results: "Talks less while in class",
      },
      observation_report: {
        psychological: [
          {
            id: "bc",
            grade: "9",
            year: "2001",
            report:
              "Agatha has a strong mental character and she is less emotional in dire situations",
          },
        ],
        social: [
          {
            id: "bb",
            grade: "10",
            year: "2002",
            report: "Agatha has serious challenges with making new friends",
          },
        ],
        overall_impression: [
          {
            id: "b9",
            grade: "12",
            year: "2004",
            report:
              "The girl has improved socially, she made 3 friends in her chemistry class",
          },
        ],
      },
      scholastic_achievements: {
        primary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: "1995",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: "2",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          level_of_grade: {
            col1: "b",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          english_home: {
            col1: "Y",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: "mn",
              name: "Mathematics",
              cols: {
                col1: "90",
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: "    90",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: "A",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: "P",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: "G",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },

        secondary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: "11",
            col9: "11",
            col10: "12",
            col11: "12",
          },
          level_of_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            H: "I",
            col10: "H",
            col11: "I",
          },
          english_home: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: null,
              name: null,
              cols: {
                col1: null,
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },
      },
      general_information: {
        outstanding_aptitudes_interests: {
          aptitudes: "Very good in mathematics",
          interests: "Loves football and hockey",
        },
        outstanding_achievements_attained: {
          academic: "Won the spelling bee three times in a role",
          extra_curricukar:
            "Still holds the trophy for best soccer player in the district",
        },
        vocational_choice: {
          careers_chosen_by_learner: "Doctor",
          careers_chosen_by_parents: "Nurse",
          counsellors_recommendations: "Pilot",
          broad_vocational_field: "Business science",
          specific_careers: "Accountant, life coach",
        },
      },
      general_remarks:
        "Agather is overally a great student, she is excellent in mathematics and maths related fields. There are no challenges that she cannot tackle.",
    },
    /////////STUDENT DATA CHUNK END//////////

    {
      id: "1",
      student_current_grade: "10",
      student_current_class: "10b",
      identification: {
        name: "Agatha",
        other: null,
        surname: "Matthews",
        identity_number: "qaeworflsel",
        residential_address: "North Industrial area, Windhoek, Namibia",
        postal_address: "Northen Industrial zone post office",
        gender: "female",
        name_of_guardian: "George Matthews",
        name_of_mother: "Martha Thompson",
        occupation_of_guardian: "Plumber",
        ocuupation_of_mother: "Doctor",
        home_phone: "061344893",
        business_phone: "0813685221",
        church: "Angican",
        home_language: "Afrikaans",
      },
      schools_attended: {
        exemption_from_compulsory_education: false,
        date: null,
        age_on_initial_entry_to_school: "9",
        schools_details: [
          {
            id: "a1",
            admission_no: "11224",
            name_of_school: "Amazing kids",
            medium: null,
            date_of_admission: "1/1/2002",
            grade_of_admission: "8",
            date_of_departure: "11/12/2009",
            grade_of_departure: "7",
          },
          {
            id: "a2",
            admission_no: "11223",
            name_of_school: "Windhoek High",
            medium: null,
            date_of_admission: "12/06/2009",
            grade_of_admission: "8",
            date_of_departure: "28/08/2010",
            grade_of_departure: "9",
          },
          {
            id: "a3",
            admission_no: "11224",
            name_of_school: "DHPS",
            medium: null,
            date_of_admission: "12/06/2010",
            grade_of_admission: "9",
            date_of_departure: "12/11/2014",
            grade_of_departure: "12",
          },
        ],
      },
      physical_condition: [
        {
          id: "a1",
          date: "june 28",
          general_health: "normal",
          problem: "asthmatic",
          current_solution: "Inhaler",
          previous_illness: "chest pains",
        },
      ],
      psychometric_data: [
        {
          id: null,
          date: null,
          name_of_test: null,
          grade: null,
          tester: null,
          remarks: null,
        },
      ],
      learning_disabilities: {
        nature: null,
        action_taken: null,
        results: null,
      },
      problematic_behaviour: {
        nature_of_offence: "Too talkative",
        action_action_taken: "Daily detention",
        results: "Talks less while in class",
      },
      observation_report: {
        psychological: [
          {
            id: "bc",
            grade: "9",
            year: "2001",
            report:
              "Agatha has a strong mental character and she is less emotional in dire situations",
          },
        ],
        social: [
          {
            id: "bb",
            grade: "10",
            year: "2002",
            report: "Agatha has serious challenges with making new friends",
          },
        ],
        overall_impression: [
          {
            id: "b9",
            grade: "12",
            year: "2004",
            report:
              "The girl has improved socially, she made 3 friends in her chemistry class",
          },
        ],
      },
      scholastic_achievements: {
        primary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: "1995",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: "2",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          level_of_grade: {
            col1: "b",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          english_home: {
            col1: "Y",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: "mn",
              name: "Mathematics",
              cols: {
                col1: "90",
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: "    90",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: "A",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: "P",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: "G",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },

        secondary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: "11",
            col9: "11",
            col10: "12",
            col11: "12",
          },
          level_of_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            H: "I",
            col10: "H",
            col11: "I",
          },
          english_home: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: null,
              name: null,
              cols: {
                col1: null,
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },
      },
      general_information: {
        outstanding_aptitudes_interests: {
          aptitudes: "Very good in mathematics",
          interests: "Loves football and hockey",
        },
        outstanding_achievements_attained: {
          academic: "Won the spelling bee three times in a role",
          extra_curricukar:
            "Still holds the trophy for best soccer player in the district",
        },
        vocational_choice: {
          careers_chosen_by_learner: "Doctor",
          careers_chosen_by_parents: "Nurse",
          counsellors_recommendations: "Pilot",
          broad_vocational_field: "Business science",
          specific_careers: "Accountant, life coach",
        },
      },
      general_remarks:
        "Agather is overally a great student, she is excellent in mathematics and maths related fields. There are no challenges that she cannot tackle.",
    },
    /////////STUDENT DATA CHUNK END//////////

    {
      id: "1",
      student_current_grade: "10",
      student_current_class: "10b",
      identification: {
        name: "Agatha",
        other: null,
        surname: "Matthews",
        identity_number: "qaeworflsel",
        residential_address: "North Industrial area, Windhoek, Namibia",
        postal_address: "Northen Industrial zone post office",
        gender: "female",
        name_of_guardian: "George Matthews",
        name_of_mother: "Martha Thompson",
        occupation_of_guardian: "Plumber",
        ocuupation_of_mother: "Doctor",
        home_phone: "061344893",
        business_phone: "0813685221",
        church: "Angican",
        home_language: "Afrikaans",
      },
      schools_attended: {
        exemption_from_compulsory_education: false,
        date: null,
        age_on_initial_entry_to_school: "9",
        schools_details: [
          {
            id: "a1",
            admission_no: "11224",
            name_of_school: "Amazing kids",
            medium: null,
            date_of_admission: "1/1/2002",
            grade_of_admission: "8",
            date_of_departure: "11/12/2009",
            grade_of_departure: "7",
          },
          {
            id: "a2",
            admission_no: "11223",
            name_of_school: "Windhoek High",
            medium: null,
            date_of_admission: "12/06/2009",
            grade_of_admission: "8",
            date_of_departure: "28/08/2010",
            grade_of_departure: "9",
          },
          {
            id: "a3",
            admission_no: "11224",
            name_of_school: "DHPS",
            medium: null,
            date_of_admission: "12/06/2010",
            grade_of_admission: "9",
            date_of_departure: "12/11/2014",
            grade_of_departure: "12",
          },
        ],
      },
      physical_condition: [
        {
          id: "a1",
          date: "june 28",
          general_health: "normal",
          problem: "asthmatic",
          current_solution: "Inhaler",
          previous_illness: "chest pains",
        },
      ],
      psychometric_data: [
        {
          id: null,
          date: null,
          name_of_test: null,
          grade: null,
          tester: null,
          remarks: null,
        },
      ],
      learning_disabilities: {
        nature: null,
        action_taken: null,
        results: null,
      },
      problematic_behaviour: {
        nature_of_offence: "Too talkative",
        action_action_taken: "Daily detention",
        results: "Talks less while in class",
      },
      observation_report: {
        psychological: [
          {
            id: "bc",
            grade: "9",
            year: "2001",
            report:
              "Agatha has a strong mental character and she is less emotional in dire situations",
          },
        ],
        social: [
          {
            id: "bb",
            grade: "10",
            year: "2002",
            report: "Agatha has serious challenges with making new friends",
          },
        ],
        overall_impression: [
          {
            id: "b9",
            grade: "12",
            year: "2004",
            report:
              "The girl has improved socially, she made 3 friends in her chemistry class",
          },
        ],
      },
      scholastic_achievements: {
        primary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: "1995",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: "2",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          level_of_grade: {
            col1: "b",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          english_home: {
            col1: "Y",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: "mn",
              name: "Mathematics",
              cols: {
                col1: "90",
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: "    90",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: "A",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: "P",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: "G",
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },

        secondary_school: {
          phase: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          year_and_month: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: "11",
            col9: "11",
            col10: "12",
            col11: "12",
          },
          level_of_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            H: "I",
            col10: "H",
            col11: "I",
          },
          english_home: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },

          subjects: [
            {
              id: null,
              name: null,
              cols: {
                col1: null,
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
                col8: null,
                col9: null,
                col10: null,
                col11: null,
              },
            },
          ],

          average_symbol_learner: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          average_symbol_grade: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          pass_fail: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          school_attendence: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
          attendence_grading: {
            col1: null,
            col2: null,
            col3: null,
            col4: null,
            col5: null,
            col6: null,
            col7: null,
            col8: null,
            col9: null,
            col10: null,
            col11: null,
          },
        },
      },
      general_information: {
        outstanding_aptitudes_interests: {
          aptitudes: "Very good in mathematics",
          interests: "Loves football and hockey",
        },
        outstanding_achievements_attained: {
          academic: "Won the spelling bee three times in a role",
          extra_curricukar:
            "Still holds the trophy for best soccer player in the district",
        },
        vocational_choice: {
          careers_chosen_by_learner: "Doctor",
          careers_chosen_by_parents: "Nurse",
          counsellors_recommendations: "Pilot",
          broad_vocational_field: "Business science",
          specific_careers: "Accountant, life coach",
        },
      },
      general_remarks:
        "Agather is overally a great student, she is excellent in mathematics and maths related fields. There are no challenges that she cannot tackle.",
    },
    /////////STUDENT DATA CHUNK END//////////
  ],
};

const mainExReducer = (state = initState, action) => {
  return state;
};

export default mainExReducer;
