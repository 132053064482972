const initState = {
  students: [],
  teachers: [],
  hods: [],
  users: [],
};

const studentsReducer = (state = initState, action) => {
  return state;
};

export default studentsReducer;
